import React, { useState, useContext } from 'react';
import { Panel, PanelType, ChoiceGroup, IChoiceGroupOption, IconButton, Overlay, Text } from '@fluentui/react';
import { useLanguage } from '../../../state/LanguageProvider';
import { useChatContext } from '../../../state/ChatContextProvider';
import { Language } from '../../../api/models';
import {useTranslation } from "../../../state/LanguageProvider";
import styles from './MobileChatSettings.module.css';
import commonStyles from '../CommonChatSettingsStyles/CommonChatSettings.module.css';
import { updateObjectKeyInLocalStorage } from '../../../utils/localStorageUtils';

import ChatSettingIcon from '../../../assets/Chat_Settings_Purple.svg';
import CloseIcon from "../../../assets/Close_Icon.svg";

interface MobileChatSettings {
    isMultiContext: boolean;
    isMultiLang: boolean;
}

type contextType = {
    key: string;
    text: string;
}

const MobileChatSettings: React.FC<MobileChatSettings> = ({isMultiLang, isMultiContext}) => {
    const [isPanelOpen, setIsPanelOpen] = useState(false);
    const { chatContextKey, setChatContextKey, contextExists, chatRegionKey, setChatRegionKey, regionExists, availableChatContexts, botName } = useChatContext();
    const { language, setLanguage } = useLanguage();
    const { getTranslation} = useTranslation();
    
    const chatContextOptions:any = []
    const chatRegionOptions:any = []
    const identifyContext: any = availableChatContexts.map(function(items) {
        Object.entries(items).map(([key, text]) => {
            if (key.toLowerCase() === "contexts") {
                chatContextOptions.push(Object.entries(text).map(([key, text]) => ({key, text })))
            } else if (key.toLowerCase() === "region") {
                chatRegionOptions.push(Object.entries(text).map(([key, text]) => ({key, text })))
            }
        });
    });

    const languageOptions: IChoiceGroupOption[] = [
        { key: 'en-ca', text: getTranslation("en-CA") },
        { key: 'fr-ca', text: getTranslation("fr-CA") }
    ];

    const openPanel = () => setIsPanelOpen(true);
    const closePanel = () => setIsPanelOpen(false);
    const handleLanguageChange = (optionKey: string|undefined) => {  
        // Function to handle language change  
        setLanguage(optionKey as Language);
        updateObjectKeyInLocalStorage(botName, 'lang', optionKey)
            
    }; 
      
    const handleRegionChange = (optionKey: string|undefined) => {  
        // Function to handle region change  
        setChatRegionKey(optionKey as string)
        updateObjectKeyInLocalStorage(botName, 'region', optionKey)
    }; 

    const handleContextChange = (optionKey: string|undefined) => {  
        // Function to handle context change  
        setChatContextKey(optionKey as string)
        let dynamicKey = `context_${language}`; 
        updateObjectKeyInLocalStorage(botName, dynamicKey, optionKey)
    }; 

    const mappingsObj: any = availableChatContexts.find((item) => item.contextMappings)?.contextMappings || {};
    

    // Function to filter chatContextOptions based on region selection (chatRegionKey)
    const getFilteredContextOptions = (regionKey: string) => {
        // If no region or regionMappings, return the full context options
        if (!regionKey || !mappingsObj || Object.keys(mappingsObj).length === 0) {
            return chatContextOptions;
        }

        // Find the region key matching the selected region
        const mappedKeys = mappingsObj[regionKey] || [];

        // Filter the chatContextOptions based on the mapped context keys for the region
        return chatContextOptions.filter((context:contextType) => mappedKeys.includes(context.key));
    };

    // Get filtered chatContextOptions based on the current region selection
    const filteredChatContextOptions = getFilteredContextOptions(chatRegionKey);

    if (!filteredChatContextOptions.some((option: contextType) => option.key === chatContextKey) && filteredChatContextOptions.length > 0) {
        setChatContextKey(filteredChatContextOptions[0].key);
    }

    return (
        <div className={styles.chatSettingsButtonContainer}>
            <img src={ChatSettingIcon} title="Settings" aria-label={getTranslation("settings")} onClick={openPanel} className={commonStyles.chatSettingsButton}/>

            <Panel
                isOpen={isPanelOpen}
                onDismiss={closePanel}
                type={PanelType.customNear}
                customWidth="80%"
                isLightDismiss
                className={styles.chatSettingsPanel}
                onRenderNavigationContent={() => (
                    <img src={CloseIcon} className={styles.closeIcon} aria-label={getTranslation("close_panel")} onClick={closePanel} />
                )}
            >
                <div className={commonStyles.panelHeader}>
                    <img src={ChatSettingIcon} title="Settings" aria-label={getTranslation("settings")} onClick={openPanel} className={commonStyles.chatSettingsButton}/>
                    <Text className={commonStyles.panelTitle}>{getTranslation("chat_settings")}</Text>
                </div>

                {isMultiLang && (
                <div className={commonStyles.choiceGroupContainer}>
                    <ChoiceGroup
                        label={getTranslation("language")}
                        selectedKey={language}
                            onChange={(_, option) => handleLanguageChange(option?.key)}
                        options={languageOptions}
                        styles={{ label: commonStyles.choiceGroupLabel, root: commonStyles.choiceGroupOption }}
                    />
                </div>)}

                {(isMultiContext && regionExists) && (
                <div className={commonStyles.choiceGroupContainer}>
                    <ChoiceGroup
                                label={getTranslation("help_me_with")}
                                selectedKey={chatRegionKey}
                                onChange={(_, option) => handleRegionChange(option?.key)}
                        options={chatRegionOptions[0]}
                        styles={{ label: commonStyles.choiceGroupLabel, root: commonStyles.choiceGroupOption }}
                    />
                </div>)}

                {(isMultiContext && contextExists) && (
                <div className={commonStyles.choiceGroupContainer}>
                    <ChoiceGroup
                        label={getTranslation("help_me_with")}
                        selectedKey={chatContextKey}
                                onChange={(_, option) => handleContextChange(option?.key)}
                                options={filteredChatContextOptions}
                        styles={{ label: commonStyles.choiceGroupLabel, root: commonStyles.choiceGroupOption }}
                    />
                </div>)}

            </Panel>

            {isPanelOpen && <Overlay />}
        </div>
    );
};

export default MobileChatSettings;
