import ChatContextButton from './ChatContextButton';
import { useChatContext } from '../../state/ChatContextProvider';
import { Stack } from '@fluentui/react';
import { useTranslation } from '../../state/LanguageProvider';

type ContextType = Record<string, string>; // Allows dynamic key-value storage

const ChatContextSelection = () => {
    const { chatContextKey, setChatContextKey, availableChatContexts, chatRegionKey } = useChatContext();
    const { getTranslation } = useTranslation();

    // Extract contexts, mappings, and regions from availableChatContexts
    const contextsObj: ContextType = availableChatContexts.find((item) => item.contexts)?.contexts || {};
    
    // Ensure mappingsObj is explicitly typed as ContextMappingsType
    const mappingsObj: any = availableChatContexts.find((item) => item.contextMappings)?.contextMappings || {};
    
    const regionObj = availableChatContexts.find((item) => item.region)?.region || null;

    // Function to compute context based on chatRegionKey
    const getFilteredContexts = (): ContextType => {
        // If regionObj doesn't exist, return all contexts without filtering
        if (!regionObj) return contextsObj;

        // Find the region key matching the selected region
        const regionKey = Object.keys(regionObj).find((key) => key === chatRegionKey);

        // If regionKey is not found, return all contexts
        if (!regionKey) return contextsObj;

        // Get mapped context keys for the region
        const mappedKeys = mappingsObj[regionKey] || [];

        if (mappedKeys.length === 0) return contextsObj;

        // Filter contexts based on mapping
        return Object.fromEntries(Object.entries(contextsObj).filter(([key]) => mappedKeys.includes(key)));
    };

    // Get the filtered contexts based on region selection
    const filteredContexts = getFilteredContexts();

    return (
        <div>
            <Stack horizontal tokens={{ childrenGap: 20 }}>
                {Object.entries(filteredContexts).map(([key, text]) => (
                    <ChatContextButton
                        key={key}
                        label={`${getTranslation('help_me_with')} ${text}`}
                        isActive={chatContextKey === key}
                        onClick={() => setChatContextKey(localStorage.getItem('context') || key)}
                    />
                ))}
            </Stack>
        </div>
    );
};

export default ChatContextSelection;
