import { DefaultButton, IButtonProps, IButtonStyles, ICommandBarStyles } from "@fluentui/react";
import { QuestionCircle20Filled} from "@fluentui/react-icons";
import styles from "./Button.module.css";
import { useTranslation } from "../../state/LanguageProvider";
import UserGuide from "../../assets/User_Guide_Icon.svg";

interface UserGuideButtonProps extends IButtonProps {}

export const UserGuideButton: React.FC<UserGuideButtonProps> = () => {

    const {getTranslation} = useTranslation();
    const userguideButtonStyles: ICommandBarStyles & IButtonStyles = {
        root: {
          width: 32,
          height: 32,
          borderRadius: 4,
          background: '#4b286d',
          transition: 'background-color 200ms, border-color 200ms',
          padding: '5px 12px',
          marginRight: '20px',
          borderTopLeftRadius: '32px',
          borderTopRightRadius: '32px',
          borderBottomRightRadius: '32px',
          borderBottomLeftRadius: '32px',
          borderWidth: '1px'
        },
        icon: {
          color: '#FFFFFF'
        },
        iconHovered: {
          color: '#FFFFFF',
        },
        rootHovered: {
          background: 'rgb(43, 128, 0)',
          transition: 'background-color 200ms, border-color 200ms',
        },
        label: {
          fontWeight: 600,
          fontSize: '16px',
          lineHeight: '24px',
          color: '#FFFFFF',
          fontFamily: 'HelveticaNow400normal',
          display: 'flex',
          alignItems: 'center'
        },

      };

  return (
    <div
      className={styles.copyButtonContainer}
      title={getTranslation("user_guide")}
      role="button"
      tabIndex={0}
      aria-label="UserGuide"
    >
      <div
        className={styles.copyButtonContainer}
        title={getTranslation("user_guide")}
        role="button"
        tabIndex={0}
        aria-label="UserGuide"
      >
        <img
          src={UserGuide}
          alt="User Guide Icon"
          className={styles.userguideButton}
        />
      </div>


    </div>
  )
}

interface HistoryButtonProps extends IButtonProps {
    onClick: () => void;
    text: string;
  }

export const HistoryButton: React.FC<HistoryButtonProps> = ({onClick, text}) => {
    const historyButtonStyles: ICommandBarStyles & IButtonStyles = {
        root: {
            width: '180px',
            border: `1px solid #D1D1D1`,
          },
          rootHovered: {
            border: `1px solid #D1D1D1`,
          },
          rootPressed: {
            border: `1px solid #D1D1D1`,
          },
      };

      return (
        <DefaultButton
            text={text}
            iconProps={{ iconName: 'History' }}
            onClick={onClick}
            styles={historyButtonStyles}
        />
      )
}