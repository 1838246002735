/* Different chatbots have different user contexts, hence making the key dynamic such that
relevant key value pair is stored in the local storage. keys can be lang, region, context_en-ca,
context_en-fr, provinces and more */
export interface LocalStorageObject {  
  [key: string]: string;
}  

export const setObjectInLocalStorage = (key: string, value: LocalStorageObject) => {  
  localStorage.setItem(key, JSON.stringify(value));  
};  

export const getObjectFromLocalStorage = (key: string): LocalStorageObject | null => {  
  const storedValue = localStorage.getItem(key);  
  if (storedValue) {  
    return JSON.parse(storedValue) as LocalStorageObject;  
  }  
  return null;  
};  

/* key is the bot name with env which serves as the local storage key. 
The value is an object where the object's key and value is also passed to this function */
export const updateObjectKeyInLocalStorage = (key: string, objectKey: string, objectVal: string|undefined) : void | null => {  
  let storedObject = getObjectFromLocalStorage(key); 
  // if the localastorage for that bot in the env does not exist then set the key value pair
  if(storedObject == null)
  {
      let newStoreObject: LocalStorageObject = {  
        [objectKey]: objectKey as string
      };
      newStoreObject[objectKey] = objectVal as string;
      setObjectInLocalStorage(key,newStoreObject); 
  }
  else {
      storedObject[objectKey] = objectVal as string;
      setObjectInLocalStorage(key,storedObject); 
  }
};  

