import React, { useEffect} from 'react';
import ReactDOM from "react-dom/client";
import { HashRouter, Routes, Route } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";
import { AppInsightsContext } from '@microsoft/applicationinsights-react-js';
import { appInsights, reactPlugin } from './api/telemetric';
import "./index.css";
import { preloadIcons, iconUrls } from "./utils/preloadIcons"; 
import { trackEvent, trackException } from "../src/api/telemetric";

import Layout from "./pages/layout/Layout";
import NoPage from "./pages/NoPage";
import Chat from "./pages/chat/Chat";
import { AppStateProvider } from "./state/AppProvider";
import { LanguageProvider } from './state/LanguageProvider';
import { ChatContextProvider } from './state/ChatContextProvider';
import { FrontendSettings,frontendSettings } from './api';

initializeIcons();

export default function App() {
    useEffect(() => {
        const getFrontendSettings = async () => {
            frontendSettings().then((response) => {
                const appSettings = response as FrontendSettings;
                appInsights.config.instrumentationKey=appSettings.APPINSIGHTS_INSTRUMENTATIONKEY
                appInsights.loadAppInsights();
            })
            .catch((err) => {
                console.error("There was an issue fetching your data.");
            })
        }
        getFrontendSettings();
    }, []);

    useEffect(() => {
        // Preload icons to prevent delay when the component is mounted
        preloadIcons(iconUrls, 3, 1000)
          .then(() => {
            trackEvent("IconsPreloaded", { status: "success" });
          })
          .catch((err) => {
            trackException(`An error occurred during icon preloading: ${err.message}`);
          });
      }, []);
    
    return (
        <AppInsightsContext.Provider value={reactPlugin}>
            <AppStateProvider>
                <LanguageProvider>
                    <ChatContextProvider>
                        <HashRouter>
                            <Routes>
                                <Route path="/" element={<Layout />}>
                                    <Route index element={<Chat />} />
                                    <Route path="*" element={<NoPage />} />
                                </Route>
                            </Routes>
                        </HashRouter>
                    </ChatContextProvider>
                </LanguageProvider>
            </AppStateProvider>
        </AppInsightsContext.Provider>
    );
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
