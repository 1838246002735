import * as React from 'react';
import { Stack } from "@fluentui/react";
import { Toggle } from '@fluentui/react/lib/Toggle';
import styles from "./FeedBackButton.module.css";
import { useTranslation } from "../../state/LanguageProvider";

interface Props {
    onSelectionChange: (feedbackOptions: any[]) => void;
}

export const FeedBackList = ({ onSelectionChange }: Props) => {

    const { getTranslation } = useTranslation();

    const options: any[] = [
        { key: 'inaccurate', text: getTranslation('inaccurate'), selected: false },
        { key: 'harmful', text: getTranslation('harmful'), selected: false },
        { key: 'outofdate', text: getTranslation('out_of_date'), selected: false },
        { key: 'tooshort', text: getTranslation('too_short'), selected: false },
        { key: 'nothelpful', text: getTranslation('not_helpful'), selected: false },
    ];

    const [feedbackOptions, setFeedbackOptions] = React.useState(options)
    const onChange = (option: any, checked?: boolean): void => {
        const updatedFeedBackOptions = feedbackOptions.map(feedBackOption =>
            option.key == feedBackOption.key ? { ...feedBackOption, selected: checked } : feedBackOption
        );
        setFeedbackOptions(updatedFeedBackOptions);
        onSelectionChange(updatedFeedBackOptions)
    }
    const renderFeedBackOptions = () => {
        return feedbackOptions.map((option) => {
            return <Toggle
                label={option.text}
                onChange={(ev, checked) => onChange(option, checked)}
                styles={{
                    pill: {
                        backgroundColor: option.selected == true ? '#2b8000' : '#ffff',
                        fontFamily: "HelveticaNowTELUS",
                        selectors: {
                            '&:hover': {
                                backgroundColor: option.selected ? '#2b8000' : 'default', // Change hover color
                            }
                        }
                    }
                }}
            />
        })
    }

    return (<Stack className={styles.toggleContainer}>
        {

            renderFeedBackOptions()
        }
    </Stack>);
};
