import ClearHistory from "../assets/Clear_Chat_Icon.svg";
import StopGeneratingEN from "../assets/Stop_Generating.jpg";
import StopGeneratingFR from "../assets/Stop_Generating_FR.jpg";
import ThumbsUp from "../assets/Thumbs_Up_Icon.svg";
import ThumbsDown from "../assets/Thumbs_Down_Icon.svg";
import Copy from "../assets/Copy_Icon.svg";
import Regenerate from "../assets/Regenerate_Icon.svg";
import DownloadIcon from "../assets/Download_Icon_White.svg";
import CloseIcon from "../assets/Close_Icon.svg";
import SendIcon from "../assets/Send_Icon.svg";
import SendIconActive from "../assets/Send_Icon_Active.svg";
import StopGeneratingIcon from "../assets/Stop_Generating_Icon.svg";
import TelusLogoEN from "../assets/TELUS_Logo_English.svg";
import TelusLogoFR from "../assets/TELUS_Logo_French.svg";
import UserGuide from "../assets/User_Guide_Icon.svg";

// Doesn`t preload all icons
export const preloadIcons = (urls: string[], retries = 3, delay = 1000) => {
  const loadImage = (url: string, retriesLeft: number): Promise<void> => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = url;
      img.onload = () => resolve(); // Loaded

      // On error, retry x times with y sec delay
      img.onerror = () => {
        if (retriesLeft > 0) {
          setTimeout(() => {
            loadImage(url, retriesLeft - 1)
              .then(resolve)
              .catch(reject);
          }, delay); 
        } else {
          reject(new Error(`Failed to load image: ${url}`));
        }
      };
    });
  };

  return Promise.all(
    urls.map((url) => loadImage(url, retries))
  );
};

export const iconUrls = [
  ClearHistory,
  StopGeneratingEN,
  StopGeneratingFR,
  ThumbsUp,
  ThumbsDown,
  Copy,
  Regenerate,
  DownloadIcon,
  CloseIcon,
  SendIcon,
  SendIconActive,
  StopGeneratingIcon,
  TelusLogoFR,
  TelusLogoEN,
  UserGuide
];
