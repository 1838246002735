import React, { useState, useEffect, useRef } from 'react';
import { Dropdown, IDropdownOption } from '@fluentui/react';
import ChevronRight from "../../assets/Chevron_Right_Icon.svg";
import ChevronDown from "../../assets/Chevron_Down_Icon.svg";

interface ChatContextDropdownProps {
    //label: string;
    placeholder: string;
    selectedKey: string | undefined;
    options: IDropdownOption[];
    onChange: (event: React.FormEvent<HTMLDivElement>, option?: IDropdownOption) => void;
}

const ChatContextDropdown: React.FC<ChatContextDropdownProps> = ({
    //label,
    placeholder,
    selectedKey,
    options,
    onChange,
}) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef<HTMLDivElement>(null);

    const onClick = () => setIsOpen((prevState) => !prevState);
    const handleClickOutside = (event: MouseEvent) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
          setIsOpen(false); // Dropdown is closed
        }
      };
    
      useEffect(() => {
        if (isOpen) {
          document.addEventListener('mousedown', handleClickOutside);
        } else {
          document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
          document.removeEventListener('mousedown', handleClickOutside);
        };
      }, [isOpen]);

    const customDropdownStyles = {
        //label: { font: '700', color: '#2C2E30', fontSize: '16px' },
        dropdown: { fontFamily: 'HelveticaNowTELUS', borderRadius: 10, maxWidth: 236, border: '1px solid #2C2E30', marginBottom: 12,},
        title: { fontFamily: 'HelveticaNowTELUS', color: '#2C2E30', borderRadius: 10, borderColor: '#2C2E30',},
        dropdownItem: { fontFamily: 'HelveticaNowTELUS', color: '#2C2E30' },
        dropdownItemSelected: { fontFamily: 'HelveticaNowTELUS', backgroundColor: '#F2EFF4', color: '#4B286D', fontWeight: '700', 
            selectors: { ':hover': { color: '#4B286D' } },
        },
        callout: { fontFamily: 'HelveticaNowTELUS', borderRadius: '10px', marginTop: '6px' },
        caretDownWrapper: { display: 'flex', alignItems: 'center', justifyContent: 'center',
        },
    };

    return (
        <div className="dropdown-container">
            <Dropdown
                //label={label}
                placeholder={placeholder}
                selectedKey={selectedKey}
                options={options}
                onChange={onChange}
                styles={customDropdownStyles}
                onClick={onClick}
                onRenderCaretDown={() => (
                  <div style={customDropdownStyles.caretDownWrapper}>
                    <img
                      src={isOpen ? ChevronDown : ChevronRight}
                      alt={isOpen ? 'ChevronDown' : 'ChevronRight'}
                      style={{ width: '12px', height: '12px' }}
                    />
                  </div>
                )}
              />
            </div>
          );
        };
                  

export default ChatContextDropdown;
