import { useEffect, useContext, useMemo, useState } from "react";
import { useBoolean } from "@fluentui/react-hooks"
import { FontIcon, IconButton, Stack, Text, IIconProps } from "@fluentui/react";
import { CopyRegular, CopyFilled, ArrowRepeatAll20Regular} from "@fluentui/react-icons";
import { LangfuseWeb } from "langfuse";
import styles from "./Answer.module.css";

import { AskResponse, Citation, FeedBack, FeedBackStatus } from "../../api";
import { parseAnswer } from "./AnswerParser";

import ReactMarkdown from "react-markdown";
import remarkGfm from "remark-gfm";
import supersub from 'remark-supersub'
import { trackEvent,trackMetrics,trackTrace } from "../../api/telemetric";
import { FeedBackButton } from "../FeedBackButton";

import { AppStateContext } from "../../state/AppProvider";
import { FrontendSettings } from "../../api";
import { useTranslation } from "../../state/LanguageProvider";

import Copy from "../../assets/Copy_Icon.svg";
import Regenerate from "../../assets/Regenerate_Icon.svg";
import ChevronRight from "../../assets/Chevron_Right_Icon.svg";
import ChevronDown from "../../assets/Chevron_Down_Icon.svg";

interface Props {
    answer: AskResponse;
    onCitationClicked: (citedDocument: Citation) => void;
    onRegenerateClicked: () => void;
}

export const Answer = ({
    answer,
    onCitationClicked,
    onRegenerateClicked
}: Props) => {
    const [isRefAccordionOpen, { toggle: toggleIsRefAccordionOpen }] = useBoolean(false);
    const filePathTruncationLimit = 50;
    const { getTranslation } = useTranslation();

    const parsedAnswer = useMemo(() => parseAnswer(answer), [answer]);
    const {question} = answer;
    const [chevronIsExpanded, setChevronIsExpanded] = useState(isRefAccordionOpen);
    const [copyClicked, setCopyClicked] = useState<boolean>(false);
    const [copyText, setCopyText] = useState<string>("Copy");

    const appStateContext = useContext(AppStateContext);
    const frontEndSettings: FrontendSettings = appStateContext?.state.frontendSettings!;
    const {LANGFUSE_PUBLIC_KEY: publicKey = '', LANGFUSE_HOST:baseUrl = ''} = frontEndSettings;
   
    const handleChevronClick = () => {
        setChevronIsExpanded(!chevronIsExpanded);
        toggleIsRefAccordionOpen();
    };

    const langfuseWeb = new LangfuseWeb({
      publicKey: publicKey,
      baseUrl: baseUrl
    });
  
    const handleLangfuseUserFeedback = async (responseStatus: FeedBack, feedback: string) => {

        const value = (responseStatus === FeedBackStatus.LIKED ? 1 : -1);
        
        await langfuseWeb.score({
            id: parsedAnswer.answerId,
            traceId: parsedAnswer.langfuseTraceId,
            name: "user-feedback",
            value: value,
            comment: feedback
          });
    }
      
    const handleFeedBackClick = (responseStatus: FeedBack, feedback: string) => {
        const regex = / \^[\d]+\^ /g;
        const botResponse = parsedAnswer.markdownFormatText.replace(regex, '');
        trackTrace(`FeedBack:${responseStatus} Bot Response:${botResponse}`);

        // For custom event - feedback status only
        const additionalProperties = { "question": question, "Bot Response": botResponse };
        trackEvent(typeof responseStatus == 'undefined' ? '':responseStatus,additionalProperties);
        trackMetrics(typeof responseStatus == 'undefined' ? '':responseStatus, 1, additionalProperties);

        // Log the user feedback as a langfuse trace score
        handleLangfuseUserFeedback(responseStatus, feedback);
    }

    const handleCopyClick = () => {
        const regex = / \^[\d]+\^ /g; // Define a regular expression to match the pattern for subscript
        navigator.clipboard.writeText(parsedAnswer.markdownFormatText.replace(regex, ''));
        setCopyClicked(true);
        setTimeout(function () {
            setCopyClicked(false);
        }, 4000);
        trackTrace(`User copied the response`)
    };

    useEffect(() => {
        setChevronIsExpanded(isRefAccordionOpen);
        if (copyClicked) {
            setCopyText("Copied");
        }
    }, [isRefAccordionOpen, copyClicked]);

    const createCitationFilepath = (citation: Citation, index: number, truncate: boolean = false) => {
        let citationFilename = "";

        if (citation.filepath && citation.chunk_id) {
            if (truncate && citation.filepath.length > filePathTruncationLimit) {
                const citationLength = citation.filepath.length;
                citationFilename = `${citation.filepath.substring(0, 20)}...${citation.filepath.substring(citationLength - 20)} - Part ${parseInt(citation.chunk_id) + 1}`;
            }
            else {
                citationFilename = `${citation.filepath} - Part ${parseInt(citation.chunk_id) + 1}`;
            }
        }
        else if (citation.filepath && citation.reindex_id) {
            citationFilename = `${citation.filepath} - Part ${citation.reindex_id}`;
        }
        else if (citation.org_name && citation.reindex_id) {
            citationFilename = `Org ${citation.org_id}-${citation.org_name}`;
        }
        else {
            citationFilename = `Citation ${index}`;
        }
        return citationFilename;
    }

    return (
        <>
            <Stack className={styles.answerContainer} tabIndex={0}>
                <Stack.Item grow>
                    <ReactMarkdown
                        linkTarget="_blank"
                        remarkPlugins={[remarkGfm, supersub]}
                        children={parsedAnswer.markdownFormatText}
                        className={styles.answerText}
                    />
                </Stack.Item>

                <Stack horizontal className={styles.answerFooter}>
                    {!!parsedAnswer.citations.length && (
                        <Stack.Item
                            onKeyDown={e => e.key === "Enter" || e.key === " " ? toggleIsRefAccordionOpen() : null}
                        >
                            <Stack style={{ width: "100%" }} >
                                <Stack horizontal horizontalAlign='start' verticalAlign='center'>
                                    <Text
                                        className={styles.accordionTitle}
                                        onClick={toggleIsRefAccordionOpen}
                                        aria-label="Open references"
                                        tabIndex={0}
                                        role="button"
                                    >
                                        <span>
                                            {
                                                parsedAnswer.citations.length > 1 ? parsedAnswer.citations.length + getTranslation('references') 
                                                : "1" + getTranslation('reference')
                                            }
                                        </span>
                                    </Text>
                                    <div onClick={handleChevronClick}>
                                        <img 
                                          className={styles.accordionIcon}
                                          src={chevronIsExpanded ? ChevronDown : ChevronRight} 
                                          alt="Chevron Icon" 
                                        />
                                    </div>
                                </Stack>

                            </Stack>
                        </Stack.Item>
                    )}

                    {chevronIsExpanded &&
                    <div className={styles.references}>
                        {parsedAnswer.citations.map((citation, idx) => {
                            return (
                                <span
                                    title={createCitationFilepath(citation, ++idx)}
                                    tabIndex={0}
                                    role="link"
                                    key={idx}
                                    onClick={() => onCitationClicked(citation)}
                                    onKeyDown={e => e.key === "Enter" || e.key === " " ? onCitationClicked(citation) : null}
                                    className={styles.citationContainer}
                                    aria-label={createCitationFilepath(citation, idx)}
                                >
                                    <div className={styles.citation}>{idx}</div>
                                    {createCitationFilepath(citation, idx, true)}
                                </span>);
                        })}
                    </div>
                }

                    {question !="" && answer.answer != "" && (
                        <Stack.Item className={styles.answerDisclaimerContainer}>
                         <span className={styles.answerDisclaimer}>{getTranslation('ai_content_warning')}</span>
                        </Stack.Item>)
                    }

                    {question !="" && answer.answer != "" && (
                    <Stack horizontal className={styles.answerHeader}>
                        <Stack.Item>
                            <Stack style={{ width: "100%" }} >
                                <Stack horizontal horizontalAlign='start' verticalAlign='center'>
                                </Stack>
                            </Stack>
                        </Stack.Item>
                        <Stack.Item className={styles.answerActionContainer}>

                            <FeedBackButton question={question} onFeedBackClicked={handleFeedBackClick} />
                            
                            <div
                                className= {`${styles.copyButtonContainer} ${copyClicked ? styles.activeButtonContainer : ''}`}
                                title={getTranslation('copy_response')}
                                role="button"
                                tabIndex={0}
                                aria-label={getTranslation('copy_response')}
                                onClick={handleCopyClick}
                                onKeyDown={e => e.key === "Enter" || e.key === " " ? handleCopyClick() : null}
                            >
                                <svg className={`${copyClicked ? styles.activeButton : styles.inactiveButton}`} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path fill-rule="evenodd" clip-rule="evenodd" d="M7.23324 1C6.64092 1 6.07287 1.2353 5.65404 1.65413C5.23521 2.07296 4.99991 2.64102 4.99991 3.23333V4H4.22992C3.63834 4 3.07006 4.23435 2.65071 4.6537C2.23331 5.0711 1.99658 5.63576 1.99658 6.2301V13.7668C1.99658 14.3591 2.23188 14.9271 2.65071 15.346C3.06954 15.7648 3.6376 16.0001 4.22992 16.0001H8.75991C9.3471 16.0001 9.92389 15.7684 10.3463 15.346C10.7658 14.9265 10.9999 14.3582 10.9999 13.7668V13.0001H11.7699C12.3633 13.0001 12.9283 12.7645 13.3463 12.3465C13.7694 11.9234 13.9999 11.3464 13.9999 10.7601V5.39456C13.9999 5.11265 13.9505 4.81605 13.8294 4.5283C13.7106 4.24611 13.5352 3.99934 13.329 3.79772L11.1191 1.6365C10.7029 1.22954 10.1449 1.00007 9.55991 1H7.23324ZM10.1133 11.2001C10.1089 11.2 10.1044 11.2 10.0999 11.2C10.0954 11.2 10.091 11.2 10.0865 11.2001H7.23324C7.11741 11.2001 7.00745 11.1543 6.92683 11.0737C6.84915 10.996 6.79991 10.8802 6.79991 10.7601V4.90338C6.79991 4.90225 6.79991 4.90113 6.79991 4.9C6.79991 4.89887 6.79991 4.89775 6.79991 4.89662V3.23333C6.79991 3.11841 6.84556 3.00819 6.92683 2.92692C7.00809 2.84565 7.11831 2.8 7.23324 2.8H9.55991C9.67164 2.80004 9.77876 2.8434 9.8607 2.9235L12.0708 5.08494C12.1186 5.13162 12.1514 5.18153 12.1704 5.2267C12.1871 5.26634 12.1999 5.32148 12.1999 5.39478V10.7601C12.1999 10.8811 12.1506 10.9967 12.0735 11.0737C11.9915 11.1558 11.8837 11.2001 11.7699 11.2001H10.1133ZM4.99991 5.8V10.7601C4.99991 11.3473 5.23161 11.9241 5.65404 12.3465C6.07351 12.766 6.64183 13.0001 7.23324 13.0001H9.19991V13.7668C9.19991 13.8826 9.15414 13.9926 9.07352 14.0732C8.99584 14.1509 8.87997 14.2001 8.75991 14.2001H4.22992C4.11499 14.2001 4.00477 14.1544 3.9235 14.0732C3.84224 13.9919 3.79658 13.8817 3.79658 13.7668V6.2301C3.79658 6.11719 3.84081 6.00919 3.9235 5.92649C4.00425 5.84575 4.11425 5.8 4.22992 5.8H4.99991Z"/>
                                </svg>
                                  
                            </div>

                            {parsedAnswer.isLastAnswer && (
                                <Stack
                                horizontal
                                className={styles.regenerateContainer}
                                role="button"
                                title={getTranslation('regenerate_response')}
                                aria-label={getTranslation('regenerate_response')}
                                tabIndex={0}
                                onClick={onRegenerateClicked}
                                // onKeyDown={e => e.key === "Enter" || e.key === " " ? regenerateResponse(lastQuestionRef.current) : null}
                                >
                                    <svg className={styles.regenerateIcon} width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M14.9786 2.48941C15.0293 1.99494 14.6695 1.55301 14.175 1.50232C13.6806 1.45163 13.2386 1.81138 13.188 2.30585L13.0469 3.68157C11.8033 2.29214 9.92236 1.50017 7.82001 1.50017C4.40436 1.50017 1.53459 3.81575 1.0426 7.37698C0.974578 7.86936 1.31859 8.32366 1.81097 8.39168C2.30335 8.4597 2.75764 8.11569 2.82567 7.62331C3.1893 4.99117 5.26375 3.30017 7.82001 3.30017C9.71852 3.30017 11.2355 4.1014 12.0702 5.35729L9.79689 5.11026C9.30274 5.05656 8.85862 5.41361 8.80493 5.90776C8.75123 6.40191 9.10829 6.84602 9.60243 6.89972L13.572 7.33108C13.8103 7.35697 14.049 7.28677 14.2353 7.13605C14.4216 6.98533 14.5401 6.76652 14.5646 6.52813L14.9786 2.48941ZM1.82489 15.4988C1.33043 15.4481 0.970675 15.0061 1.02136 14.5117L1.43538 10.4729C1.45982 10.2346 1.57833 10.0157 1.76464 9.86502C1.95095 9.7143 2.18968 9.6441 2.42792 9.66999L6.3975 10.1014C6.89165 10.155 7.2487 10.5992 7.19501 11.0933C7.14131 11.5875 6.69719 11.9445 6.20305 11.8908L3.92964 11.6438C4.76435 12.8997 6.28137 13.7009 8.17989 13.7009C10.7363 13.7009 12.8109 12.0096 13.1743 9.37713C13.2423 8.88474 13.6965 8.54068 14.1889 8.60865C14.6813 8.67662 15.0254 9.13088 14.9574 9.62326C14.4658 13.1849 11.5958 15.5009 8.17989 15.5009C6.07756 15.5009 4.19668 14.7089 2.953 13.3195L2.81198 14.6952C2.76129 15.1897 2.31936 15.5494 1.82489 15.4988Z"/>
                                    </svg>
                                </Stack>
                            )}

                        </Stack.Item>
                    </Stack>
                )}
                </Stack>
            </Stack>
        </>
    );
};
