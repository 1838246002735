import { useRef, useState, useEffect, useContext, useLayoutEffect, useCallback } from "react";
import { CommandBarButton, IconButton, Dialog, DialogType, Stack } from "@fluentui/react";
import { SquareRegular, ShieldLockRegular, ArrowRepeatAll16Regular, ErrorCircleRegular, FastForwardRegular, ChevronDoubleRightRegular, TriangleRightRegular} from "@fluentui/react-icons";

import ReactMarkdown from "react-markdown";
import remarkGfm from 'remark-gfm'
import rehypeRaw from "rehype-raw";
import uuid from 'react-uuid';
import ReactDOM from 'react-dom';
import DOMPurify from 'dompurify';

import styles from "./Chat.module.css";
import layoutStyles from "../layout/Layout.module.css";
import ansStyles from "../../components/Answer/Answer.module.css"
import { parseAnswer } from "../../components/Answer/AnswerParser";
import { attachOnErrorHandler} from "../../utils/utils"
import {
    ChatMessage,
    ConversationRequest,
    conversationApi,
    Citation,
    ToolMessageContent,
    ChatResponse,
    getUserInfo,
    DownloadFileApi,
    Conversation,
    historyClear,
    ErrorMessage,
    getGreetingMessage,
    getApplicationInfo,
    FrontendSettings,
    AskResponse
} from "../../api";
import { useTranslation, useLanguage } from "../../state/LanguageProvider";
import { useChatContext } from '../../state/ChatContextProvider';
import { ChatSkeleton } from "./ChatSkeleton";
import { Answer } from "../../components/Answer";
import { Unauthenticated } from "../unauthenticated/Unauthenticated";
import { QuestionInput } from "../../components/QuestionInput";
import { AppStateContext } from "../../state/AppProvider";
import { useBoolean } from "@fluentui/react-hooks";
import { trackMetrics, trackEvent, trackTrace, trackException } from "../../api/telemetric";
import { isEmpty } from "lodash-es";
import { debounce } from "lodash";
import FileSaver from 'file-saver';
import ChatContextSelection from "../../components/ChatContext/ChatContextSelection";
import ProvinceSelectionDropdown from "../../components/ChatContext/ProvinceSelectionDropdown";
import ClearChatIcon from "../../assets/Clear_Chat_Icon.svg";
import StopGeneratingIcon from "../../assets/Stop_Generating_Icon.svg";
import DownloadIcon from "../../assets/Download_Icon_White.svg";
import CloseIcon from "../../assets/Close_Icon.svg";

const enum messageStatus {
    NotRunning = "Not Running",
    Processing = "Processing",
    Done = "Done"
}

const Chat = () => {
    const lastQuestionRef = useRef<string>("");
    const appStateContext = useContext(AppStateContext)
    const chatMessageStreamEnd = useRef<HTMLDivElement | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isChatbotLoading, setIsChabotLoading] = useState<boolean>(true);
    const [showLoadingMessage, setShowLoadingMessage] = useState<boolean>(false);
    const [activeCitation, setActiveCitation] = useState<Citation>();
    const [isCitationPanelOpen, setIsCitationPanelOpen] = useState<boolean>(false);
    const [isCitationPanelVisible, setIsCitationPanelVisible] = useState<boolean>(false);
    const abortFuncs = useRef([] as AbortController[]);
    const [showAuthMessage, setShowAuthMessage] = useState<boolean>(true);
    const [messages, setMessages] = useState<ChatMessage[]>([]);
    const [sasToken, setSASToken] = useState<string>("");
    const [docRef, setDocRef] = useState<string>("");
    const [processMessages, setProcessMessages] = useState<messageStatus>(messageStatus.NotRunning);
    const [clearingChat, setClearingChat] = useState<boolean>(false);
    const [hideErrorDialog, { toggle: toggleErrorDialog }] = useBoolean(true);
    const [errorMsg, setErrorMsg] = useState<ErrorMessage | null>()
    const citationPanelRef = useRef<HTMLDivElement>(null);
    const frontEndSettings: FrontendSettings = appStateContext?.state.frontendSettings!;
    const isChatSettingsEnabled = frontEndSettings?.CHAT_SETTINGS_ENABLED || false;
    const { getTranslation } = useTranslation();
    const { language} = useLanguage();
    const { availableChatContexts,chatContextKey, chatRegionKey, provinceKey, contextExists, regionExists, provinceExists} = useChatContext();
    const [showScrollButton, setShowScrollButton] = useState(false);

    const scrollToBottom = () => {
        chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" })
    };

    const checkIsAtBottom = useCallback(
        debounce((node: HTMLDivElement | null) => {
          if (!node) return;
          const { scrollTop, scrollHeight, clientHeight } = node;
      
          const isAtBottom = scrollTop + clientHeight >= scrollHeight - 10;
          setShowScrollButton(!isAtBottom);
        }, 100), // Wait 100ms before updating state
        []);
    
    // Add the callback ref to the chat container
    const chatContainerRect = useCallback((node: HTMLDivElement | null)=> {
      if (!node) return;
    
      // Listen to user scrolling
      node.addEventListener("scroll", () => checkIsAtBottom(node));
    
      // Observe for new messages being added
      const mutationObserver = new MutationObserver(() => checkIsAtBottom(node));
      mutationObserver.observe(node, { childList: true, subtree: true });
    
      return () => {
        node.removeEventListener("scroll", () => checkIsAtBottom(node));
        mutationObserver.disconnect();
      };
    }, []);

    // Set up an effect to focus on the element when the component mounts or when Citations Panel conditions are met
    useEffect(() => {
      if (isCitationPanelOpen && activeCitation && citationPanelRef.current) {
        citationPanelRef.current.focus();
      }
    }, [isCitationPanelOpen, activeCitation]);

    useEffect(() => {
        const observer = new MutationObserver((mutations) => {
          mutations.forEach((mutation) => {
            mutation.addedNodes.forEach((node) => {
              if (node.nodeType === Node.ELEMENT_NODE) {
                const element = node as HTMLElement;
      
                // If the added node is an image, attach the onerror handler
                if (element.tagName === 'IMG') {
                  attachOnErrorHandler(element as HTMLImageElement);
                }
      
                // If the added node is a DIV etc. check children for image elements
                const imgElements = element.querySelectorAll('img');
                imgElements.forEach((img) => attachOnErrorHandler(img));
              }
            });
          });
        });    
        // Observe the body with children and extended children
        observer.observe(document.body, {
          childList: true,
          subtree: true,
        });
      
        // Cleanup when component unmount
        return () => {
          observer.disconnect();
        };
    }, []);

    const notInContextResponse = [
        "Sorry, this information is not mentioned in the retrieved documents",
        "The requested information is not available in the retrieved data.",
        "The requested information is not available in the retrieved data. Please try another query or topic.",
        "The requested information is not found in the retrieved data. Please try another query or topic.",
        "Sorry"];

    const errorDialogContentProps = {
        type: DialogType.close,
        title: errorMsg?.title,
        closeButtonAriaLabel: 'Close',
        subText: errorMsg?.subtitle,
    };

    const modalProps = {
        titleAriaId: 'labelId',
        subtitleAriaId: 'subTextId',
        isBlocking: true,
        styles: { main: { maxWidth: 450 } }
    }

    const [ASSISTANT, TOOL, ERROR] = ["assistant", "tool", "error"]

    const handleErrorDialogClose = () => {
        toggleErrorDialog()
        setTimeout(() => {
            setErrorMsg(null)
        }, 500);
    }

    const [greetingMessage, setGreetingMessage] = useState<string[]>([]);

    const getInitialMessage = async () => {
        const { content } = await getGreetingMessage(language)
        setGreetingMessage(content.split('|'))
    }

    const getUserInfoList = async () => {
        const userInfoList = await getUserInfo();
        const application = await getApplicationInfo(language);
        if (userInfoList.length === 0 && window.location.hostname !== "127.0.0.1" && !application.allowAnonymousUser) {
            setShowAuthMessage(true);
            setIsChabotLoading(false);
        }
        else {
            setShowAuthMessage(false);
            setIsChabotLoading(false);
        }
    }

    let assistantMessage = {} as ChatMessage;
    let toolMessage = {} as ChatMessage
    let assistantContent = "";

    const processResultMessage = (resultMessage: ChatMessage, userMessage: ChatMessage, conversationId?: string) => {
        if (resultMessage.role === ASSISTANT) {
            assistantContent += resultMessage.content
            assistantMessage = resultMessage
            assistantMessage.content = assistantContent
        }

        if (resultMessage.role === TOOL) toolMessage = resultMessage

        if (!conversationId) {
            isEmpty(toolMessage) ?
                setMessages([...messages, userMessage, assistantMessage]) :
                setMessages([...messages, userMessage, toolMessage, assistantMessage]);
        } else {
            isEmpty(toolMessage) ?
                setMessages([...messages, assistantMessage]) :
                setMessages([...messages, toolMessage, assistantMessage]);
        }
    }

    const makeApiRequestWithoutCosmosDB = async (question: string, conversationId?: string) => {
        
        
        question = DOMPurify.sanitize(question, { ALLOWED_TAGS: [], ALLOWED_ATTR: [] }); // Sanitize HTML and scripts
        question = question.replace(/https?:\/\/[^\s]+/g, ''); // Sanitize urls
        question = question.trim() === '' ? 'Message was removed for security reasons.' : question; // Default if message is empty due to sanitization

        lastQuestionRef.current = question;
        setIsLoading(true);
        setShowLoadingMessage(true);
        const abortController = new AbortController();
        abortFuncs.current.unshift(abortController);

        const userMessage: ChatMessage = {
            id: uuid(),
            role: "user",
            content: question,
            date: new Date().toISOString(),
        };
        trackEvent(`Question`,userMessage)

        let conversation: Conversation | null | undefined;
        if (!conversationId) {
            conversation = {
                id: conversationId ?? uuid(),
                title: question,
                messages: [userMessage],
                date: new Date().toISOString(),
            }
        } else {
            conversation = appStateContext?.state?.currentChat
            if (!conversation) {
                console.error("Conversation not found.");
                setIsLoading(false);
                setShowLoadingMessage(false);
                abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
                return;
            } else {
                conversation.messages.push(userMessage);
            }
        }
        
        appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: conversation });
        setMessages(conversation.messages)
        
        trackMetrics("Question",1, userMessage)

        //extract region key value
        let region: string = "";
        availableChatContexts.forEach((items) => {
            Object.entries(items).forEach(([key, value]) => {
                if (key.toLowerCase() === "region") {
                    Object.entries(value).forEach(([subKey, subValue]) => {
                        if (subKey === chatRegionKey) {
                            region = subValue;
                        }
                    });
                } 
            });
        });
        //To handle special character case when region is UK/IE
        const selRegionName = chatRegionKey ? chatRegionKey.substring(0, chatRegionKey.lastIndexOf('_')) + "_" + region : "";
       
        const request: ConversationRequest = {
            messages: [...conversation.messages.filter((answer) => answer.role !== "error")],
            lang: language,
            province: provinceKey,
            index: chatContextKey,
            region: selRegionName,
        };

        let result = {} as ChatResponse;
        let responseContent = "";
        let error = false;

        try {
            const response = await conversationApi(request, abortController.signal);

            if (response?.body) {

                const reader = response.body.getReader();
                let runningText = "";
                while (true) {
                    setProcessMessages(messageStatus.Processing)
                    const { done, value } = await reader.read();
                    if (done) break;

                    var text = new TextDecoder("utf-8").decode(value);
                    const objects = text.split("\n");
                    objects.forEach((obj) => {
                        try {
                            runningText += obj;
                            result = JSON.parse(runningText);
                            result.choices[0].messages.forEach((resultMessage) => {
                                resultMessage.id = uuid();
                                resultMessage.date = new Date().toISOString();
                            })
                            if(!isEmpty(assistantMessage.content)){
                                setShowLoadingMessage(false);
                            }
                            
                            result.choices[0].messages.forEach((resultObj) => {
                                processResultMessage(resultObj, userMessage, conversationId);
                            })
                            runningText = "";
                        }
                        catch { }
                    });
                }
            }

        } catch (e) {
            if (!abortController.signal.aborted) {
                error = true;
                let errorMessage = "An error occurred. Please try again. If the problem persists, please contact the site administrator.";
                if (result.error?.message) {
                    errorMessage = result.error.message;
                }
                else if (typeof result.error === "string") {
                    errorMessage = result.error;
                }
                let errorChatMsg: ChatMessage = {
                    id: uuid(),
                    role: "error",
                    content: errorMessage,
                    date: new Date().toISOString()
                }
                conversation.messages.push(errorChatMsg);
                appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: conversation });
                console.error(e);
                trackEvent(`Failed Question`,{ "Question":question, "Response": `${result.error?.message ||  errorMessage}`})
                trackException(`Failed Request ${question} Reason :${result.error?.message ||  errorMessage}`)
                setMessages([...messages, errorChatMsg]);
            }
        } finally {
            if (!error && assistantMessage.content) {
                // Set the langfuse trace id 
                assistantMessage.langfuseTraceId = result.langfuse_trace_id;
                conversation.messages.push(toolMessage, assistantMessage)
                appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: conversation });
                setMessages([...messages, toolMessage, assistantMessage]);

                const citations = parseCitationFromMessage(toolMessage)
                const hasNoResponseFromContext = notInContextResponse.some(str => str.includes(assistantMessage.content));
                const additionalProperties = { "Question":question, "Response": assistantMessage.content};
                const answerResponse: AskResponse = {
                    answer: assistantMessage.content,
                    citations: citations,
                    question: question,
                    answerId: assistantMessage.id,
                    isLastAnswer: isLastMessage(assistantMessage.id)
                }
                const parsedAnswer = parseAnswer(answerResponse)
                if(parsedAnswer.citations.length == 0) {
                    trackEvent(`No Results`,additionalProperties)
                    trackTrace(`No Results ${question} Reponse :${assistantMessage.content}`, additionalProperties)
                    trackMetrics("No Results", 1, additionalProperties);
                }
            }

            setIsLoading(false);
            setShowLoadingMessage(false);
            abortFuncs.current = abortFuncs.current.filter(a => a !== abortController);
            setProcessMessages(messageStatus.Done)
        }

        return abortController.abort();
    };

    const clearChat = async () => {
        setClearingChat(true)
        if (appStateContext?.state.currentChat?.id) {
            let response = await historyClear(appStateContext?.state.currentChat.id, language)
            appStateContext?.dispatch({ type: 'DELETE_CURRENT_CHAT_MESSAGES', payload: appStateContext?.state.currentChat.id });
            appStateContext?.dispatch({ type: 'DELETE_CHAT_HISTORY' });

            if (!response.ok) {
                setErrorMsg({
                    title: "Error clearing current chat",
                    subtitle: "Please try again. If the problem persists, please contact the site administrator.",
                })
                toggleErrorDialog();
            } else {
                appStateContext?.dispatch({ type: 'DELETE_CURRENT_CHAT_MESSAGES', payload: appStateContext?.state.currentChat.id });
                appStateContext?.dispatch({ type: 'UPDATE_CHAT_HISTORY', payload: appStateContext?.state.currentChat });
                setActiveCitation(undefined);
                setIsCitationPanelOpen(false);
                setMessages([])
            }
        }
        setActiveCitation(undefined);
        setIsCitationPanelOpen(false);
        setMessages([]);
        setClearingChat(false);
    };

    useEffect(() => {
        clearChat();
        getInitialMessage();
    }, [language])

    const newChat = () => {
        setProcessMessages(messageStatus.Processing)
        setMessages([])
        setIsCitationPanelOpen(false);
        setActiveCitation(undefined);
        appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: null });
        setProcessMessages(messageStatus.Done)
    };

    const stopGenerating = () => {
        abortFuncs.current.forEach(a => a.abort());
        setShowLoadingMessage(false);
        setIsLoading(false);
    }

    const regenerateResponse = () => {
        const quest = messages[messages.length - 3];
        messages.splice(-3);
        let conversation: Conversation | null | undefined;
        conversation = appStateContext?.state?.currentChat;
        setMessages(messages);
        
        if(conversation){
            conversation.messages = messages;
            appStateContext?.dispatch({ type: 'UPDATE_CURRENT_CHAT', payload: conversation });
        }
        
        makeApiRequestWithoutCosmosDB(quest['content'], quest['id'],)
    }

    const isLastMessage = (answerId: string) => {
        if (messages.length > 0 && (answerId == messages[messages.length-1].id)) {
            return true;
        }
        return false;
    }

    const isValidURL = (url:string) => {
        // Regular expression for URL validation
        var res = url.match(/(http(s)?:\/\/.)/g);
        if(res == null)
            return false;
        else
            return true;
    }
    const downloadSource = async (activeCitation: any) => {
        if (activeCitation.url && isValidURL(activeCitation.url)) {
            window.open(activeCitation.url, "_blank");
        }
        else{
            const res = await DownloadFileApi(activeCitation.url);
            if(res.status == 200){
                const sourceBlob = await res.blob()
                FileSaver.saveAs(sourceBlob, activeCitation.filepath);
            } 
        }
    }

    useEffect(() => {
        if (appStateContext?.state.currentChat) {
            setMessages(appStateContext.state.currentChat.messages)
        } else {
            setMessages([])
        }
    }, [appStateContext?.state.currentChat]);

    useLayoutEffect(() => {
        if (appStateContext && appStateContext.state.currentChat && processMessages === messageStatus.Done) {
            appStateContext?.dispatch({ type: 'UPDATE_CHAT_HISTORY', payload: appStateContext.state.currentChat });
            setMessages(appStateContext.state.currentChat.messages)
            setProcessMessages(messageStatus.NotRunning)
        }
    }, [processMessages]);

    useEffect(() => {
        getUserInfoList();
        getInitialMessage();
    }, []);

    useEffect(() => {
        setTimeout(() => {
            setIsCitationPanelVisible(isCitationPanelOpen);
        }, 300);
    }, [isCitationPanelOpen]);

    useEffect(() => {
        setTimeout(() => {
            setIsCitationPanelOpen(isCitationPanelVisible);
        }, 300);
    }, [isCitationPanelVisible]);

    //useLayoutEffect(() => {
    //    chatMessageStreamEnd.current?.scrollIntoView({ behavior: "smooth" })
    //}, [showLoadingMessage, processMessages]);

    const onShowCitation = (citation: Citation) => {
        if (citation.url && !citation.url.includes("blob.core") && isValidURL(citation.url)) {
            window.open(citation.url, "_blank");
        } else {
            setActiveCitation(citation);
            setIsCitationPanelOpen(true);
        }
    };

    const onViewSource = (citation: Citation) => {
        if (citation.url && !citation.url.includes("blob.core")) {
            window.open(citation.url, "_blank");
        }
    };

    const parseCitationFromMessage = (message: ChatMessage) => {
        if (message?.role && message?.role === "tool") {
            try {
                const toolMessage = JSON.parse(message.content) as ToolMessageContent;
                return toolMessage.citations;
            }
            catch {
                return [];
            }
        }
        return [];
    }

    const parseQuestion =(message: ChatMessage) => {
        if (message?.role && message?.role === "user") {
            return message.content
        }
        return '';
    }

    const disabledButton = () => {
        return isLoading || (messages && messages.length === 0) || clearingChat
    }

    const portalTarget = document.getElementById('header-portal-container');

    return (
        <>
            {isChatbotLoading ? (
                <ChatSkeleton />
            ) : (
            <>
                {showAuthMessage ? (
                    <Unauthenticated />
                ) : (
                    <div className={styles.container} role="main">
                        <Stack horizontal className={styles.chatRoot}>
                            <div className={styles.chatContainer}>
                                {!lastQuestionRef.current ? (

                                    <div className={styles.chatMessageStream} style={{ marginBottom: isLoading ? "40px" : "0px" }} role="log">
                                        {greetingMessage.map(m => (
                                            <div className={styles.chatMessageGpt} tabIndex={0}>
                                                <Stack className={ansStyles.answerContainer}>
                                                    <Stack.Item className={ansStyles.answerText}>
                                                        <p>{m}</p>
                                                    </Stack.Item>
                                                </Stack>
                                            </div>
                                        ))}
                                        {(isChatSettingsEnabled && (contextExists || regionExists)) && (<ChatContextSelection/>)}
                                        {provinceExists && (<ProvinceSelectionDropdown/>)}
                                        {provinceKey && <div className={styles.chatMessageGpt} tabIndex={0}>
                                                <Stack className={ansStyles.answerContainer}>
                                                    <Stack.Item className={ansStyles.answerText}>
                                                        <p>{getTranslation('how_can_I_help' )}</p>
                                                    </Stack.Item>
                                                </Stack>
                                        </div>}
                                    </div>
                                ) : (
                                    <div ref = {chatContainerRect} className={styles.chatMessageStream} style={{ marginBottom: isLoading ? "40px" : "0px" }} role="log">
                                        {greetingMessage.map(m => (
                                            <div className={styles.chatMessageGpt} tabIndex={0}>
                                                <Stack className={ansStyles.answerContainer}>
                                                    <Stack.Item className={ansStyles.answerText}>
                                                        <p>{m}</p>
                                                    </Stack.Item>
                                                </Stack>
                                            </div>
                                        ))}

                                        {provinceExists && (<ProvinceSelectionDropdown/>)}
                                        {provinceKey && 
                                        <div className={styles.chatMessageGpt} tabIndex={0}>
                                                <Stack className={ansStyles.answerContainer}>
                                                    <Stack.Item className={ansStyles.answerText}>
                                                        <p>{getTranslation('how_can_I_help' )}</p>
                                                    </Stack.Item>
                                                </Stack>
                                        </div>}

                                        {messages.map((answer, index) => (

                                            <>
                                                {answer.role === "user" ? (
                                                    <div className={styles.chatMessageUser} tabIndex={0}>
                                                        <Stack className={ansStyles.userMessageContainer}>
                                                            <Stack.Item className={styles.chatMessageUserMessage}>
                                                                <p>{answer.content}</p>
                                                            </Stack.Item>
                                                        </Stack>
                                                    </div>

                                                ) : (
                                                    answer.role === "assistant" ? <div className={styles.chatMessageGpt}>
                                                        <Answer
                                                            answer={{
                                                                answer: answer.content,
                                                                citations: parseCitationFromMessage(messages[index - 1]),
                                                                question: parseQuestion(messages[index - 2]),
                                                                langfuseTraceId: answer.langfuseTraceId,
                                                                answerId: answer.id,
                                                                isLastAnswer: isLastMessage(answer.id)
                                                            }}
                                                            onCitationClicked={c => onShowCitation(c)}
                                                            onRegenerateClicked={regenerateResponse}
                                                        />
                                                    </div> : answer.role === "error" ? <div className={styles.chatMessageError}>
                                                        <Stack horizontal className={styles.chatMessageErrorContent}>
                                                            <ErrorCircleRegular className={styles.errorIcon} style={{ color: "rgba(182, 52, 67, 1)" }} />
                                                            <span>Error</span>
                                                        </Stack>
                                                        <span className={styles.chatMessageErrorContent}>{answer.content}</span>
                                                    </div> : null
                                                )}
                                            </>
                                        ))}
                                        {showLoadingMessage && (
                                            <>
                                                <div className={styles.chatMessageGpt}>
                                                    <Answer
                                                        answer={{
                                                            answer: getTranslation('generating_answer' ),
                                                            citations: [],
                                                            question: '',
                                                            langfuseTraceId: '',
                                                            answerId: '',
                                                            isLastAnswer: false
                                                        }}
                                                        onCitationClicked={() => null}
                                                        onRegenerateClicked={() => null}
                                                    />
                                                </div>
                                            </>
                                        )}
                                        <div ref={chatMessageStreamEnd} />
                                    </div>
                                )}

                                <Stack horizontal className={styles.chatInput}>
                                
                                    {showScrollButton && (
                                        
                                    <Stack
                                        className={styles.arrowDownContainer}
                                        role="button"
                                        //aria-label= {getTranslation('down_arrow')}
                                        tabIndex={0}
                                        onClick={scrollToBottom}
                                    >
                                        <svg className = {styles.arrowDown} width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fill-rule="evenodd" clip-rule="evenodd" d="M7.90025 1.4C7.90025 0.902944 7.49731 0.5 7.00025 0.5C6.50319 0.5 6.10025 0.902944 6.10025 1.4V12.4272L2.02663 8.35357C1.67516 8.0021 1.10531 8.0021 0.753838 8.35357C0.402366 8.70504 0.402366 9.27489 0.753838 9.62636L6.36384 15.2364C6.71531 15.5878 7.28516 15.5878 7.63663 15.2364L13.2466 9.62636C13.5981 9.27489 13.5981 8.70504 13.2466 8.35357C12.8952 8.0021 12.3253 8.0021 11.9738 8.35357L7.90025 12.4272V1.4Z"/>
                                        </svg>
                                    </Stack>
                                    
                                    )}

                                    {!isLoading && messages.length > 0 && (
                                        <>
                                            {portalTarget && ReactDOM.createPortal(
                                                <Stack onClick={clearChat} horizontal tokens={{ childrenGap: 11 }} >
                                                    <Stack className={styles.clearSessionContainer}>
                                                    <div
                                                            role="button"
                                                            title={getTranslation('clear_chat')}
                                                            className={styles.clearChatNoCosmos}
                                                            aria-label={getTranslation('clear_chat')}
                                                        >
                                                            <img
                                                                src={ClearChatIcon} 
                                                                alt={getTranslation('clear_chat')}
                                                            />
                                                        </div>

                                                        <Dialog
                                                            hidden={hideErrorDialog}
                                                            onDismiss={handleErrorDialogClose}
                                                            dialogContentProps={errorDialogContentProps}
                                                            modalProps={modalProps}
                                                        >
                                                        </Dialog>
                                                    </Stack>
                                                    <label className={layoutStyles.iconLabel}> {getTranslation('clear_chat' )} </label>
                                                </Stack>
                                            ,
                                              portalTarget
                                            )}
                                        </>)
                                    }

                                    <QuestionInput
                                        clearOnSend
                                        placeholder= {getTranslation('chat_input' )}
                                        disabled={isLoading}
                                        onSend={(question, id) => {
                                            makeApiRequestWithoutCosmosDB(question, id)
                                        }}
                                        onStop={stopGenerating}
                                        conversationId={appStateContext?.state.currentChat?.id ? appStateContext?.state.currentChat?.id : undefined}
                                    />
                                </Stack>
                            </div>
                            {/* Citation Panel */}
                            {messages && messages.length > 0 && isCitationPanelOpen && activeCitation && (
                                <Stack.Item className={`${styles.citationPanel} ${isCitationPanelVisible ? styles.visiblePanel : styles.hiddenPanel}`} role="tabpanel" aria-label={getTranslation('citations_panel')}>
                                    <Stack aria-label={getTranslation('citations_panel_header_container')} horizontal className={styles.citationPanelHeaderContainer} horizontalAlign="space-between" verticalAlign="center">
                                        <span aria-label={getTranslation('citations')} className={styles.citationPanelHeader}>{getTranslation('citations')}</span>
                                        <span title={getTranslation('close_citations_panel')} className={styles.citationPanelDismiss} >
                                            <img src={CloseIcon} aria-label={getTranslation('close_citations_panel')} onClick={() => setIsCitationPanelVisible(false)} />
                                        </span>
                                    </Stack>
                                    <div className={styles.citationPanelContentContainer} ref={citationPanelRef} tabIndex={0} >
                                        <h5 className={styles.citationPanelTitle} tabIndex={0} title={activeCitation.url && !activeCitation.url.includes("blob.core") ? activeCitation.url : activeCitation.title ?? ""} onClick={() => onViewSource(activeCitation)}>{activeCitation.title}</h5>
                                        <div tabIndex={0}>
                                            <ReactMarkdown
                                                linkTarget="_blank"
                                                className={styles.citationPanelContent}
                                                children={activeCitation.content}
                                                remarkPlugins={[remarkGfm]}
                                                rehypePlugins={[rehypeRaw]}
                                            />
                                        </div>
                                    </div>
                                    {
                                        (activeCitation.filepath || activeCitation.url) &&
                                        <Stack aria-label={getTranslation('citations_panel_footer_container')} className={styles.downloadContainer} title={getTranslation('download')}  onClick={() => downloadSource(activeCitation)}>                                           
                                            <span >
                                                <img src={DownloadIcon} aria-label={getTranslation('download_source')} />
                                            </span>    
                                            <span className={styles.downloadText}>{getTranslation('download')}</span>                                                                                   
                                        </Stack>
                                    }
                                </Stack.Item>                             
                            )}
                        </Stack>
                        <div>
                            {<p className={styles.chatFooter}>{getTranslation('pii_warning')}</p>}
                        </div>
                    </div>
                )}
            </>
            )}
        </>
    );
};

export default Chat;
