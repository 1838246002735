//const provinces: Record<string, string> = {
//    nl: "Newfoundland and Labrador (NL)",
//    pe: "Prince Edward Island (PEI)",
//    ns: "Nova Scotia (NS)",
//    nb: "New Brunswick (NB)",
//    qc: "Quebec (QC)",
//    on: "Ontario (ON)",
//    mb: "Manitoba (MB)",
//    sk: "Saskatchewan (SK)",
//    ab: "Alberta (AB)",
//    bc: "British Columbia (BC)",
//    yt: "Yukon (YT)",
//    nt: "Northwest Territories (NT)",
//    nu: "Nunavut (NU)",
//};

//export const getProvince = (): string => {
//    const code = getQueryParam('province') || ''
//    return code ? provinces[code] : ''
//}

const regionMapping: Record<string, string> = {
  "us": "AZURE_SEARCH_REGION_INDEX_US",
  "aus": "AZURE_SEARCH_REGION_INDEX_Australia",
  "ca": "AZURE_SEARCH_REGION_INDEX_CANADA",
  "uk": "AZURE_SEARCH_REGION_INDEX_UKIE"
};

export const getQueryParam = (param: string): string | null => {
    const queryParam = new URLSearchParams(window.location.search).get(param);
    return queryParam ? queryParam.toLowerCase() : null;
};

export const getRegionParam = (): string => {
  const region = getQueryParam('region');
  return region && regionMapping[region] ? regionMapping[region] : '';
};


export const isValidLanguage = (lang: string | null): boolean => {
    return lang === 'en-ca' || lang === 'fr-ca';
};

export function attachOnErrorHandler(img: HTMLImageElement) {
  img.onerror = () => {
    if (img.parentNode) {
      img.parentNode.removeChild(img);
    }
    return true;
  };
}
