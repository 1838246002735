import { useState, useContext } from "react";
import { Stack, TextField } from "@fluentui/react";
import { SendFilled, SendRegular } from "@fluentui/react-icons";
import Send from "../../assets/Send.svg";
import styles from "./QuestionInput.module.css";
import { useTranslation } from "../../state/LanguageProvider";
import SendIcon from "../../assets/Send_Icon.svg";
import SendIconActive from "../../assets/Send_Icon_Active.svg";
import StopGeneratingIcon from "../../assets/Stop_Generating_Icon.svg";


interface Props {
    onSend: (question: string, id?: string) => void;
    onStop: () => void;
    disabled: boolean;
    placeholder?: string;
    clearOnSend?: boolean;
    conversationId?: string;
}

export const QuestionInput = ({ onSend, onStop, disabled, placeholder, clearOnSend, conversationId }: Props) => {
    const [question, setQuestion] = useState<string>("");
    const { getTranslation } = useTranslation();

    const sendQuestion = () => {
        if (disabled || !question.trim()) {
            return;
        }

        if(conversationId){
            onSend(question, conversationId);
        }else{
            onSend(question);
        }

        if (clearOnSend) {
            setQuestion("");
        }
    };

    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (ev.key === "Enter" && !ev.shiftKey) {
            ev.preventDefault();
            sendQuestion();
        }
    };

    const onQuestionChange = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        setQuestion(newValue || "");
    };

    const sendQuestionDisabled = disabled || !question.trim();

    return (
        <Stack horizontal className={styles.questionInputContainer}>
            <TextField
                className={styles.questionInputTextArea}
                placeholder={placeholder}
                multiline
                resizable={false}
                borderless
                value={question}
                onChange={onQuestionChange}
                onKeyDown={onEnterPress}
                inputClassName={styles.questionInputTextField}
                styles={{
                    fieldGroup: {height: '40px', minHeight: '40px'}
                  }}
            />
            <div className={styles.questionInputSendButtonContainer} 
                role="button" 
                title={disabled ? getTranslation('stop_generating') : getTranslation('send') }
                tabIndex={0}
                aria-label="Ask question button"
            >
                {disabled ? (
                    <img 
                        src={StopGeneratingIcon} 
                        className={styles.stopGeneratingButton}  
                        alt={getTranslation('stop_generating')} 
                        onClick={onStop} 
                        onKeyDown={e => e.key === "Enter" || e.key === " " ? onStop() : null}
                        tabIndex={0}
                        //className={styles.stopGeneratingIcon} 
                    />
                ) : (
                    <img 
                        src = {sendQuestionDisabled ? SendIcon : SendIconActive} 
                        className={styles.questionInputSendButton} 
                        onClick={sendQuestion} 
                        onKeyDown={e => e.key === "Enter" || e.key === " " ? sendQuestion() : null}
                        tabIndex={0}
                    />
                )}
                
            </div>
            <div className={styles.questionInputBottomBorder} />
        </Stack>
    );
};
