import React, { useState } from 'react';
import { ChoiceGroup, IChoiceGroupOption, IconButton, Text } from '@fluentui/react';
import { useLanguage } from '../../../state/LanguageProvider';
import { useChatContext } from '../../../state/ChatContextProvider';
import { Language } from '../../../api/models';
import {useTranslation} from "../../../state/LanguageProvider";
import styles from './DesktopChatSettings.module.css';
import commonStyles from '../CommonChatSettingsStyles/CommonChatSettings.module.css';
import { updateObjectKeyInLocalStorage } from '../../../utils/localStorageUtils'

import ChevronRight from '../../../assets/Chevron_Right_Grey.svg';
import ChevronLeft from '../../../assets/Chevron_Left_Grey.svg';
import ChatSettingIcon from '../../../assets/Chat_Settings_Purple.svg';

interface DesktopChatSettingsProps {
    isMultiContext: boolean;
    isMultiLang: boolean;
}
type contextType = {
    key: string;
    text: string;
}

const DesktopChatSettings: React.FC<DesktopChatSettingsProps> = ({isMultiLang, isMultiContext}) => {
    const [isExpanded, setIsExpanded] = useState(true);
    const { chatContextKey, setChatContextKey, contextExists, chatRegionKey, setChatRegionKey, regionExists, availableChatContexts, provinceKey, botName } = useChatContext();
    const { language, setLanguage } = useLanguage();
    const { getTranslation} = useTranslation();


    const chatContextOptions:any = []
    const chatRegionOptions:any = []
    const identifyContext: any = availableChatContexts.map(function(items) {
        Object.entries(items).map(([key, text]) => {
            if (key.toLowerCase() === "contexts") {
                chatContextOptions.push(...Object.entries(text).map(([key, text]) => ({ key, text })));
            } else if (key.toLowerCase() === "region") {
                chatRegionOptions.push(Object.entries(text).map(([key, text]) => ({key, text })))
            }
        });
    })

    const languageOptions: IChoiceGroupOption[] = [
        { key: 'en-ca', text: getTranslation("en-CA") },
        { key: 'fr-ca', text: getTranslation("fr-CA") }
    ];

    const toggleExpand = () => setIsExpanded(!isExpanded);

    const handleLanguageChange = (optionKey: string|undefined) => {  
        // Function to handle language change  
        setLanguage(optionKey as Language);
        updateObjectKeyInLocalStorage(botName, 'lang', optionKey)
        
    }; 
      
    const handleRegionChange = (optionKey: string|undefined) => {  
        // Function to handle region change  
        setChatRegionKey(optionKey as string)
        updateObjectKeyInLocalStorage(botName, 'region', optionKey)
    }; 

    const handleContextChange = (optionKey: string|undefined) => {  
        // Function to handle context change  
        setChatContextKey(optionKey as string)
        let dynamicKey = `context_${language}`; 
        updateObjectKeyInLocalStorage(botName, dynamicKey, optionKey)
    }; 
    
    const mappingsObj: any = availableChatContexts.find((item) => item.contextMappings)?.contextMappings || {};
    

    // Function to filter chatContextOptions based on region selection (chatRegionKey)
    const getFilteredContextOptions = (regionKey: string) => {
        // If no region or regionMappings, return the full context options
        if (!regionKey || !mappingsObj || Object.keys(mappingsObj).length === 0) {
            return chatContextOptions;
        }

        // Find the region key matching the selected region
        const mappedKeys = mappingsObj[regionKey] || [];

        // Filter the chatContextOptions based on the mapped context keys for the region
        return chatContextOptions.filter((context:contextType) => mappedKeys.includes(context.key));
    };

    // Get filtered chatContextOptions based on the current region selection
    const filteredChatContextOptions = getFilteredContextOptions(chatRegionKey);

    if (!filteredChatContextOptions.some((option: contextType) => option.key === chatContextKey) && filteredChatContextOptions.length > 0) {
        setChatContextKey(filteredChatContextOptions[0].key);
    }

    return (
        <div className={`${styles.desktopChatSettingsContainer} ${isExpanded ? styles.desktopExpanded : styles.desktopCollapsed}`}>
            <div className={styles.desktopToggleButtonContainer} onClick={toggleExpand}>
                <img src={isExpanded ? ChevronLeft : ChevronRight} title="Toggle Settings" aria-label={getTranslation("toggle_settings")} className={styles.desktopToggleButton} />
            </div>

            {isExpanded && (
                <div className={styles.desktopSettingsContent}>
                    <div className={commonStyles.panelHeader}>
                        <img src={ChatSettingIcon} aria-label={getTranslation("settings")} className={commonStyles.chatSettingsButton}/>
                        <Text className={commonStyles.panelTitle}>{getTranslation("chat_settings")}</Text>
                    </div>

                    {isMultiLang && (
                    <div className={commonStyles.choiceGroupContainer}>
                        <ChoiceGroup
                            label={getTranslation("language")}
                            selectedKey={language}
                            onChange={(_, option) => handleLanguageChange(option?.key)}
                            options={languageOptions}
                            styles={{ label: commonStyles.choiceGroupLabel, root: commonStyles.choiceGroupOption }}
                        />
                    </div>)}


                    {(isMultiContext && regionExists) && (

                        <div className={commonStyles.choiceGroupContainer}>
                            <ChoiceGroup
                                label="Region"
                                selectedKey={chatRegionKey}
                                onChange={(_, option) => handleRegionChange(option?.key)}
                                options={chatRegionOptions[0]}
                                styles={{ label: commonStyles.choiceGroupLabel, root: commonStyles.choiceGroupOption }}
                            />
                        </div>
                    )}

                    {(isMultiContext && contextExists) && (

                        <div className={commonStyles.choiceGroupContainer}>
                            <ChoiceGroup
                                label={getTranslation("help_me_with")}
                                selectedKey={chatContextKey}
                                onChange={(_, option) => handleContextChange(option?.key)}
                                options={filteredChatContextOptions}
                                styles={{ label: commonStyles.choiceGroupLabel, root: commonStyles.choiceGroupOption }}
                            />
                        </div>
                    )}

                </div>
            )} 
        </div>
    );
};

export default DesktopChatSettings;
